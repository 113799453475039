'use client'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Avatar, Input, Tooltip, Radio, Space, DatePicker, Select } from 'antd'
import { addEventTag, isMobileDevice } from 'helpers/utilities'
import { getCountryId, getCountryKey } from 'helpers/country'
import { usePathname } from 'next/navigation'
import dayjs from 'dayjs'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { LinkContext } from 'app/components/providers/linkProvider'

import { uploadUserAvatarService } from 'store/services/users/uploadUserAvatar'
import { updateUserCompleteProfileService } from 'store/services/users/updateUserCompleteProfile'

import { avatar3 } from '../../images'

import { getDefaultAvatarNumber, getUrlSearchString, isChatProcess, jumpPage } from '../utils'
import Step from '../Step'
import SafeInfo from '../Common/SafeInfo'
import AvatarModal from '../AvatarModal'
import { ArrowIcon, AvatarIcon, HelperIcon, InfoIcon, UploadIcon } from './icons'
import BaseProfileH5 from './h5'

import LoadingButton from '../Common/LoadingButton'
import { useMainResumeContext } from '../Main/context'
import { formatNationality } from './utils'
import { CustomRadio } from '../CustomRadio'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import { BackIcon } from '../Common/icons'

const BaseProfile = (props: { lang: any; config: any }) => {
  const { lang = {} } = props
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const [loading, setLoading] = useState(false)
  const profile = lang.profile || {}
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  const countryId = getCountryId()
  // console.log('profile::', profile)

  const steps = [
    {
      title: profile.steps?.text1,
      content: 'First-content'
    },
    {
      title: profile.steps?.text2,
      content: 'Second-content'
    }
  ]

  const experiencedList = [
    {
      label: lang?.profile?.experienced,
      value: 1
    },
    {
      label: lang?.profile?.freshGraduate,
      value: 2
    }
  ]

  const sgIdentityList = useMemo(() => {
    return props.config?.user_identity_lists || []
  }, [props.config])

  const noticePeriodList = useMemo(() => {
    return props.config?.notice_period_lists || []
  }, [props.config])

  const nationalityList = useMemo(() => {
    return formatNationality(props.config?.nationality_lists) || []
  }, [props.config])

  const country_national = useMemo(() => {
    return props.config?.country_national || []
  }, [props.config])

  const defaultNationalityId = useMemo(() => {
    if (countryId == 241) return undefined
    return country_national.find((item) => item.country_id === countryId)?.national_id
  }, [countryId, country_national])

  // console.log('experiencedList::', experiencedList)
  // console.log('sgIdentityList::', sgIdentityList)
  // console.log('noticePeriodList::', noticePeriodList)
  // console.log('nationalityList', nationalityList)

  const [openAvatarModal, setOpenAvatarModal] = useState(false)

  const countryKey = getCountryKey()
  const isSG = countryKey === 'sg'
  const chatProcess = isChatProcess()

  const [hasWorkExp, setHasWorkExp] = useState(false)
  const [needNextStep, setNeedNextStep] = useState(true)

  const [formData, setFormData] = useState({
    avatar: null,
    avatar_file: null,
    first_name: null,
    last_name: null,
    work_exp: null,
    availability: null,
    sg_identity: null,
    work_since: null,
    select_avatar_index: null,
    nationality_id: defaultNationalityId
  })

  const disabledSubmit = useMemo(() => {
    if (formData.work_exp == 1 && !formData.work_since) {
      return true
    }
    if (isSG && !formData.sg_identity) {
      return true
    }

    if (formData.first_name?.length > 50 || formData.last_name?.length > 50) {
      return true
    }

    return !formData.first_name || !formData.last_name || !formData.availability || !formData.avatar
  }, [formData])

  // console.log('disabledSubmit::', { disabledSubmit, formData })

  useEffect(() => {
    // console.log('userDetail::', userDetail)
    if (userDetail?.id) {
      const {
        first_name,
        last_name,
        avatar,
        notice_period_id,
        is_basic_info,
        working_since,
        is_fresh_graduate,
        user_identity,
        is_resume,
        is_educations,
        is_work_experiences,
        nationality_id
      } = userDetail

      const numberPart = getDefaultAvatarNumber(avatar)

      setFormData({
        ...formData,
        select_avatar_index: numberPart || null,
        avatar: avatar || avatar3.src,
        avatar_file: null,
        first_name: first_name || null,
        last_name: last_name || null,
        work_exp: !is_basic_info ? null : is_fresh_graduate ? 2 : 1,
        availability: notice_period_id || null,
        sg_identity: user_identity ? user_identity.id : null,
        work_since: working_since ? dayjs(working_since) : null,
        nationality_id: nationality_id || defaultNationalityId
      })
      // 是否进入一下步骤
      setNeedNextStep(!(is_resume || (is_educations && is_work_experiences)))
      setHasWorkExp(!is_basic_info ? false : is_fresh_graduate ? false : true)
    }
  }, [JSON.stringify(userDetail)])

  const handleBack = () => {
    if (chatProcess) {
      const searchWords = getUrlSearchString(1)
      push(`${pathname}${searchWords}`)
    } else {
      window.history.back()
    }
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        notice_period_id: formData.availability,
        is_fresh_graduate: formData.work_exp == 1 ? 0 : 1 //0 有经验的，1应届生
      }

      if (countryKey === 'sg') {
        payload['user_identity_id'] = formData.sg_identity
      }

      if (formData.work_exp == 1) {
        payload['working_since'] = formData.work_since
          ? formData.work_since.format('YYYY-MM-DD')
          : null
      }

      if (formData.nationality_id) {
        payload['nationality_id'] = formData.nationality_id
      }

      handleUpdateProfile(payload)
      // push(`${pathname}?step=3`)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUploadAvatar = () => {
    setOpenAvatarModal(true)
  }

  const handleUpdateProfile = async (payload) => {
    console.log('payload', { payload, formData })

    setLoading(true)
    const searchWords = getUrlSearchString(3)

    addEventTag(chatProcess ? 'new_user_chat_basic_next' : 'new_user_complete_basic_next')

    if (formData.avatar_file) {
      let newFile = formData.avatar_file
      if (formData.select_avatar_index) {
        const filename = `icon-avatar-${formData.select_avatar_index}.png`
        newFile = new File([formData.avatar_file], filename, { type: formData.avatar_file.type })
      }
      // console.log('newFile', newFile)
      await uploadUserAvatarService(newFile).catch((error) => {
        console.log('error', error)
      })
    }

    updateUserCompleteProfileService(payload)
      .then((res) => {
        if (res.data) {
          if (getUserInfo) {
            getUserInfo().then(() => {
              // if (needNextStep) {
              //   let url = `${pathname}${searchWords}`
              //   push(url)
              // } else {
              //   jumpPage()
              // }
              if (chatProcess && needNextStep) {
                let url = `${pathname}${searchWords}`
                push(url)
              } else {
                jumpPage()
              }
            })
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => setLoading(false))
  }

  const handleAvatarImageFile = (file: any, index: number) => {
    console.log('get file', file, index)
    setFormData({
      ...formData,
      avatar: URL.createObjectURL(file),
      avatar_file: file,
      select_avatar_index: index
    })
    setOpenAvatarModal(false)
  }

  const isMobile = isMobileDevice()

  if (isMobile) {
    return <BaseProfileH5 {...props} />
  }

  const validateNameErrorTip = (first, last) => {
    if (first?.length > 50 || last?.length > 50) {
      return false
    }
    return true
  }

  const disabledDate = (current) => {
    return current && current > dayjs()
  }

  return (
    <WrapContainer>
      <div className={'baseProfile yoloJobseekerCompletedSelect'}>
        <div className={'baseProfile_main'}>
          {/* <AvatarIcon className={'baseProfile_icon'} /> */}
          <h1 className={'baseProfile_title'}>
            {profile.steps?.text1}{' '}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='9'
              height='9'
              viewBox='0 0 9 9'
              fill='none'
            >
              <circle cx='4.5' cy='4.5' r='4.5' fill='#3CEAF6' />
            </svg>
          </h1>
          <p className={'baseProfile_subtitle'}>{profile.baseProfile?.subtitle}</p>

          <div className={'baseProfile_avatar'}>
            <div className={'baseProfile_avatarImg'} onClick={handleUploadAvatar}>
              <Avatar className={'baseProfile_avatarPic'} src={formData.avatar} alt='avatar' />
              <UploadIcon className={'baseProfile_uploadIcon'} />
            </div>
            <p className={'baseProfile_avatarText'}>
              <InfoIcon /> {profile.baseProfile?.avatarTip}
            </p>
          </div>

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.baseProfile?.fullName}</p>
            <p className={'baseProfile_info'}>
              {profile.baseProfile?.fullNameTip}
              <Tooltip
                overlayStyle={{ maxWidth: '400px' }}
                placement='top'
                title={profile.baseProfile?.fullNameMoreTip}
              >
                <HelperIcon className={'baseProfile_helperIcon'} />
              </Tooltip>
            </p>
            <div className={'baseProfile_value'}>
              <Input
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: (e.target.value || '').trimStart() })
                }
                className={'baseProfile_input'}
                maxLength={100}
                placeholder={profile.baseProfile?.firstName}
              />
              <Input
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: (e.target.value || '').trimStart() })
                }
                className={'baseProfile_input'}
                maxLength={100}
                placeholder={profile.baseProfile?.lastName}
              />
            </div>
            {validateNameErrorTip(formData.first_name, formData.last_name) ? null : (
              <p className={'baseProfile_error'}>{profile.baseProfile?.checkNameTip}</p>
            )}
          </div>

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.baseProfile?.workExperience}</p>
            <div className={'baseProfile_value'}>
              {/* <Radio.Group
                className={'baseProfile_radio'}
                value={formData.work_exp}
                onChange={(e) => {
                  setHasWorkExp(e.target.value == 1)
                  setFormData({ ...formData, work_exp: e.target.value })
                }}
              >
                <Space direction='vertical'>
                  {experiencedList.map((item) => (
                    <Radio value={item.value} key={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group> */}

              <div className={'baseProfile_radioGroup'}>
                {experiencedList.map((item) => (
                  <CustomRadio
                    checked={item.value == formData.work_exp}
                    label={item.label}
                    value={item.value}
                    key={item.value}
                    onChange={(e) => {
                      console.log('e', e.target.value)
                      setHasWorkExp(item.value == 1)
                      setFormData({ ...formData, work_exp: e.target.value })
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          {hasWorkExp && (
            <div className={'baseProfile_item'}>
              <p className={'baseProfile_label'}>{profile.baseProfile?.startWorkSince}</p>
              <div className={'baseProfile_value'}>
                <DatePicker
                  picker='month'
                  className={'baseProfile_date'}
                  value={formData.work_since}
                  inputReadOnly={true}
                  disabledDate={disabledDate}
                  placeholder={profile.baseProfile?.startWorkSincePlaceholder}
                  onChange={(e) => {
                    setFormData({ ...formData, work_since: e })
                  }}
                />
              </div>
            </div>
          )}

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.availability}</p>
            <p className={'baseProfile_info'}>{profile.availabilityTips}</p>
            <div className={'baseProfile_value'}>
              {/* <Radio.Group
                className={'baseProfile_radio'}
                value={formData.availability}
                onChange={(e) => setFormData({ ...formData, availability: e.target.value })}
              >
                <Space direction='vertical'>
                  {noticePeriodList.map((item) => (
                    <Radio value={item.id} key={item.id}>
                      {item.value}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group> */}

              <div className={'baseProfile_radioGroup'}>
                {noticePeriodList.map((item) => (
                  <CustomRadio
                    checked={item.id == formData.availability}
                    label={item.value}
                    value={item.id}
                    key={item.id}
                    onChange={(e) => {
                      setFormData({ ...formData, availability: e.target.value })
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          {isSG && (
            <div className={'baseProfile_item'}>
              <p className={'baseProfile_label'}>{profile.singaporeIdentity}</p>
              <div className={'baseProfile_value'}>
                {/* <Radio.Group
                  className={'baseProfile_radio'}
                  value={formData.sg_identity}
                  onChange={(e) => setFormData({ ...formData, sg_identity: e.target.value })}
                >
                  <Space direction='vertical'>
                    {sgIdentityList.map((item) => (
                      <Radio value={item.id} key={item.id}>
                        {item.value}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group> */}

                <div className={'baseProfile_sgRadioGroup'}>
                  {sgIdentityList.map((item) => (
                    <CustomRadio
                      checked={item.id == formData.sg_identity}
                      label={item.value}
                      value={item.id}
                      key={item.id}
                      onChange={(e) => {
                        setFormData({ ...formData, sg_identity: e.target.value })
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.nationality}</p>
            <p className={'baseProfile_info'}>{profile.nationalityTips}</p>
            <div className={'baseProfile_value'}>
              <Select
                allowClear
                style={{ height: '42px', width: '100%' }}
                options={nationalityList}
                suffixIcon={<ArrowIcon />}
                popupClassName='selectGlobalPopupClassName'
                placeholder={profile.nationalityPlaceholder}
                value={formData.nationality_id}
                onChange={(value) => {
                  setFormData({ ...formData, nationality_id: value })
                }}
              />
            </div>
          </div>

          <div className={'baseProfile_footer'}>
            {/* {chatProcess ? (
              <>
                <div className={'baseProfile_backBtn'} onClick={handleBack}>
                  <BackIcon />
                  {profile.back}
                </div>
              </>
            ) : (
              <div style={{ pointerEvents: 'none' }}></div>
            )} */}

            {/* <LoadingButton
              className={classNames('baseProfile_continue')}
              loading={loading}
              disabledSubmit={disabledSubmit || loading}
              onSubmit={handleSubmit}
              text={needNextStep ? profile.continue : profile.confirm}
            /> */}

            <div className={'baseProfile_backBtn'} onClick={handleBack}>
              <BackIcon />
              {profile.back}
            </div>

            <Button
              loading={loading}
              disabled={disabledSubmit || loading}
              onClick={handleSubmit}
              variant='primaryBtn'
              btnSize='size44'
            >
              {needNextStep && chatProcess ? profile.continue : profile.confirm}
            </Button>
          </div>
        </div>
      </div>
      <div className={'baseProfile_safeInfo'}>
        <SafeInfo lang={lang} />
      </div>

      {openAvatarModal && (
        <AvatarModal
          open={openAvatarModal}
          lang={lang}
          onOk={handleAvatarImageFile}
          selectAvatarIndex={formData.select_avatar_index}
          onCancel={() => setOpenAvatarModal(false)}
        />
      )}
    </WrapContainer>
  )
}

export default BaseProfile

const WrapContainer = styled.div`
  .baseProfile {
    box-sizing: border-box;
    margin: 70px auto 40px auto;

    &_step {
      margin-bottom: 24px;
      padding-left: 48px;
    }

    &_main {
      display: flex;
      margin: 0 auto;
      width: 700px;
      min-height: 600px;
      padding: 40px 32px;
      flex-direction: column;
      align-items: start;
      box-sizing: border-box;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 4px 6px 0px rgba(162, 183, 203, 0.1);
    }

    &_icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    &_title {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      width: 100%;

      svg {
        margin-left: 2px;
      }
    }

    &_subtitle {
      color: #3b3c3c;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-top: 8px;
      width: 100%;
      text-align: center;
    }

    &_avatar {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }

    &_avatarImg {
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-avatar {
        width: 100%;
        height: 100%;
      }
    }

    &_avatarPic {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #fff;
      background: #f2f2f2;
      box-shadow: 0px 12px 24px 0px rgba(162, 183, 203, 0.15);
    }

    &_uploadIcon {
      position: absolute;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      z-index: 1;
      bottom: 0;
      right: 0;
    }

    &_avatarText {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 16px;

      color: #3b3c3c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      svg {
        flex-shrink: 0;
      }
    }

    &_item {
      margin-top: 40px;
      width: 100%;
    }

    &_label {
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 6px;
    }

    &_info {
      color: #3b3c3c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &_helperIcon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      margin-left: 4px;
      vertical-align: middle;
      cursor: pointer;
      transform: translateY(-1px);
    }

    &_helperIcon:hover path {
      fill: #515151;
    }

    &_value {
      margin-top: 12px;
      display: flex;
      gap: 8px;
    }

    &_radio {
      .ant-space {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    &_radioGroup {
      display: flex;
      gap: 40px;
    }

    &_sgRadioGroup {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_input {
      width: 50%;
      height: 42px;
      outline: none;
      padding: 4px 11px;
      border-width: 1.2px;
      color: rgba(0, 0, 0, 0.88);
      border-style: solid;
      font-size: 16px;
    }

    &_input::placeholder {
      color: #646566;
    }

    &_date {
      height: 42px;
      border-radius: 4px;
      width: 100%;
      border-width: 1.2px;
    }

    &_safeInfo {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    &_footer {
      margin-top: 32px;
      display: flex;
      gap: 13px;
      justify-content: space-between;
      width: 100%;
    }

    &_back {
      outline: none;
      border: 0;
      display: flex;
      height: 44px;
      padding: 10px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 30px;
      border: 1px solid #e4e4e4;

      color: #515151;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
      background: #fff;
    }

    &_backBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #111212;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    &_error {
      color: red;
      margin-top: 8px;
    }

    &_continue {
      outline: none;
      border: 0;
      display: flex;
      height: 44px;
      padding: 10px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      background: #2378e5;

      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }

    &_continue_disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
